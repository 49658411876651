import { Col, Image, Row } from 'antd';
import React, { useEffect, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import hLogo from '../../assets/images/Hlogo.svg';
import logo from '../../assets/images/logo.svg';
import Router from '../../router/Router';
import { deleteLocalData, getAccessToken, getLocalData, setLocalData } from '../../utils/helper';
import './_upgradation.scss';
import { useDispatch, useSelector } from 'react-redux';
import { authActions } from '../../store/auth';
import { identify, trackData } from '../../utils/trackers';

const UpgradationLayout: React.FC = () => {
  const navigate = useNavigate();

  useEffect(() => {
    return () => {
      if (getLocalData('upgradetoken')) {
        deleteLocalData('upgradetoken');
      }
    };
  }, []);

  const dispatch = useDispatch(),
  [searchParams, setSearchParams] = useSearchParams(),
  t = searchParams.get('t'),
  u = searchParams.get('u'),
  [accessToken, setAccessToken] = useState(getAccessToken() as any),
  source = searchParams.get('source'),
  uhi = useSelector((state: any) => state.auth.uhi);

  useEffect(() => {
    if (u && t && getAccessToken()) {
      dispatch(authActions.logout());
    }
    if (u && t) {
      setLocalData('upgradetoken', { t: t, u: u });
      trackData('FTI Via Link', { source: source, u: u, t: t });
    }
  }, [u, t]);

  // useEffect(() => {
  //   if ((u && t) || accessToken) {
  //     checkFTIStatus();
  //   }
  // }, [u, t, accessToken, uhi]);

  useEffect(() => {
    if (u && t && getLocalData('mixpanel')) {
      identify(u);
    }
  }, [u, t, getLocalData('mixpanel')])

  useEffect(() => {
    setAccessToken(getAccessToken());
  }, []);

  return (
    <div className="uhi-upgradation">
      <Router />
    </div>
  );
};

export default UpgradationLayout;

import { Col, Row, Spin } from 'antd';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import api from '../../api/api';
import Navbar from '../../components/Navbar/Navbar';
import NavbarFooter from '../../components/Navbar/NavbarFooter';
import HeaderStrip from '../../components/header-strip/HeaderStrip';
import Router from '../../router/Router';
import { authActions } from '../../store/auth';
import { dataActions } from '../../store/data';
import { deleteLocalData, getLocalData } from '../../utils/helper';

interface Props {
  loading: boolean;
}

declare global {
  interface Window {
    webengage: any;
  }
}

const useWindowSize = () => {
  const [windowSize, setWindowSize] = useState({
    width: window.innerWidth,
    height: window.innerHeight,
  });

  useEffect(() => {
    const handleResize = () => {
      setWindowSize({
        width: window.innerWidth,
        height: window.innerHeight,
      });
    };

    window.addEventListener('resize', handleResize);

    // Call handleResize to set initial size
    handleResize();

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return windowSize;
};

const DashboardLayout: React.FC<Props> = ({ loading }) => {
  const dispatch = useDispatch(),
    { width } = useWindowSize(),
    organisation_uuid = useSelector((state: any) => state.auth?.organisation_uuid),
    member_uuid = useSelector((state: any) => state.auth?.member_uuid),
    getChoices = () => {
      api.products
        .selectChoices({ organisation_id: organisation_uuid })
        .then((response) => {
          dispatch(dataActions.setChoices({ choices: response?.data }));
        })
        .catch((err: any) => {});
    },
    getOrgProducts = () => {
      api.products
        .getProducts({ organisation_uuid: organisation_uuid })
        .then((response) => {
          dispatch(dataActions.setOrgProducts({ orgProducts: response?.[0] }));
          if (response?.[0]?.policies?.length + response?.[0]?.subscriptions?.length === 0) {
            dispatch(authActions.setRoles({ roles: ['employee'] }));
          }
        })
        .catch((error) => {});
    },
    getUserDetails = () => {
      api.employee
        .getEmployeeDetails({ organisation_uuid: organisation_uuid, member_uuid: member_uuid })
        .then((response: any) => {
          dispatch(dataActions.setUserDetails({ userDetails: response?.data?.[0] }));
          window.webengage?.user.setAttribute('are_offers_available', response?.data?.[0]?.has_offers);
          window.webengage?.user.setAttribute('dependents', response?.data?.[0]?.dependent);
          window.webengage?.user.setAttribute('employee_id', response?.data?.[0]?.organisation?.employee_id);
          window.webengage?.user.setAttribute(
            'joining_date',
            new Date(response?.data?.[0]?.organisation?.joining_date)
          );
          window.webengage?.user.setAttribute(
            'leaving_date',
            new Date(response?.data?.[0]?.organisation?.leaving_date)
          );
          window.webengage?.user.setAttribute('policies', response?.data?.[0]?.policies);
          window.webengage?.user.setAttribute('subscriptions', response?.data?.[0]?.subscriptions);
          window.webengage?.user.setAttribute('super_top_ups', response?.data?.[0]?.super_top_up);
        })
        .catch((error) => {});
    },
    choices = useSelector((state: any) => state.data.choices),
    roles = useSelector((state: any) => state.auth.roles),
    userDetails = useSelector((state: any) => state.data.userDetails),
    activeMode = useSelector((state: any) => state.auth.activeMode),
    orgProducts = useSelector((state: any) => state.data.orgProducts);

  useEffect(() => {
    if (orgProducts === null && choices === null && userDetails === null && organisation_uuid && member_uuid) {
      getUserDetails();
      getChoices();
    }
  }, [choices, orgProducts, userDetails, organisation_uuid, member_uuid]);

  useEffect(() => {
    if (roles?.includes('employer')) {
      getOrgProducts();
    }
  }, [roles]);

  useEffect(() => {
    return () => {
      if (getLocalData('upgradetoken')) {
        deleteLocalData('upgradetoken');
      }
    };
  }, []);

  useEffect(() => {
    if (width < 991 && activeMode === 'employer') {
      dispatch(authActions.changeActiveMode('employee'));
    }
  }, [width, activeMode, dispatch]);

  return (
    <>
      {loading && (
        <div className="loader-div" style={{ maxHeight: '200px' }}>
          <div className="loader">
            <Spin />
          </div>
        </div>
      )}
      {!loading && (
        <>
          <HeaderStrip />
          {/* <ContactCardMobile relationship_managers={relationship_managers} activeMode={activeMode} /> */}
          <Row className="container">
            <Col className="navbar-wrapper">
              <Navbar />
            </Col>
            <Col className="router">
              <Row>
                <Col span={24} className="router-component">
                  <Router />
                </Col>
              </Row>
            </Col>
          </Row>
          <div className="show-on-mobile">
            <NavbarFooter />
          </div>
        </>
      )}
    </>
  );
};

export default DashboardLayout;

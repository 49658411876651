import axios from 'axios';
import DOMPurify from 'dompurify';
import moment from 'moment';
import ReactGA from 'react-ga4';
import { errorNotification } from '../components/UI/Toast/Toast';
import { CURRENCY_SYMBOL, LOCALE } from '../constants/config';
let LOCAL_ITEMS = ['accessToken', 'refreshToken', 'activeMode', 'retailAccessToken', 'retailRefreshToken'];

export const getParameterByName = (name: string, url?: string) => {
  if (!url) url = window.location.href;
  /*eslint-disable */
  name = name.replace(/[\[\]]/g, '\\$&');
  /*eslint-enable */
  let regex = new RegExp('[?&]' + name + '(=([^&#]*)|&|#|$)'),
    results = regex.exec(url);
  if (!results) return null;
  if (!results[2]) return '';
  return decodeURIComponent(results[2].replace(/\+/g, ' '));
};

export const getParametersBySubstring = (name: string, url: string) => {
  if (!url) url = window.location.href;
  /*eslint-disable */
  name = name.replace(/[\[\]]/g, '\\$&');
  /*eslint-enable */
  let parts = window.location.search.slice(1).split('&');
  let result: string[] = [];
  parts.forEach((pair: any) => {
    pair = pair.split('=');
    if (pair[0].indexOf(name) > -1) {
      result.push(decodeURIComponent(pair[1]));
    }
  });
  return result.length ? result : null;
};

const STORAGE_TYPE = 'local';
export const setLocalData = (key: string, value: any) => {
  if (typeof value === 'object') {
    value = JSON.stringify(value);
  }
  if (STORAGE_TYPE === 'local') {
    window.localStorage.setItem(key, value);
  } else if (STORAGE_TYPE === 'session') {
    window.sessionStorage.setItem(key, value);
  }
};

export const getLocalData = (key: any) => {
  if (STORAGE_TYPE === 'local') {
    let returnData: any = window.localStorage.getItem(key) || null;
    try {
      returnData = JSON.parse(returnData);
    } catch (e) {
      return returnData;
    }
    return returnData;
  } else if (STORAGE_TYPE === 'session') {
    let returnData: any = window.sessionStorage.getItem(key) || null;
    try {
      returnData = JSON.parse(returnData);
    } catch (e) {
      return returnData;
    }
    return returnData;
  }
};

export const deleteLocalData = (key: string) => {
  if (STORAGE_TYPE === 'local') {
    window.localStorage?.removeItem(key);
  } else if (STORAGE_TYPE === 'session') {
    window.sessionStorage?.removeItem(key);
  }
};

export const clearLocalData = () => {
  if (STORAGE_TYPE === 'local') {
    LOCAL_ITEMS?.forEach((item) => {
      window.localStorage?.removeItem(item);
    });
  } else if (STORAGE_TYPE === 'session') {
    LOCAL_ITEMS?.forEach((item) => {
      window.sessionStorage?.removeItem(item);
    });
  }
};

export const getAccessToken = () => {
  return getLocalData('accessToken') || null;
};

export const setAccessToken = (token: string) => {
  setLocalData('accessToken', token);
};

export const setRefreshToken = (token: string) => {
  setLocalData('refreshToken', token);
};

export const getRefreshToken = () => {
  return getLocalData('refreshToken') || null;
};

//retail

export const setRetailAccessToken = (token: string) => {
  setLocalData('retailAccessToken', token);
};

export const setRetailRefreshToken = (token: string) => {
  setLocalData('retailRefreshToken', token);
};

export const getRetailRefreshToken = () => {
  return getLocalData('retailRefreshToken') || null;
};

export const getRetailAccessToken = () => {
  return getLocalData('retailAccessToken') || null;
};

export const removeAxiosHeaders = () => {
  delete axios.defaults.headers.common['Authorization'];
};

export const setAxiosHeaders = (token = getAccessToken()) => {
  (function () {
    axios.defaults.headers.common['Content-Type'] = 'application/json';
    axios.defaults.withCredentials = true;
    if (token) {
      axios.defaults.headers.common['Authorization'] = 'Bearer ' + token;
    }
  })();
};

export const setRetailAxiosHeaders = (token = getRetailAccessToken()) => {
  (function () {
    axios.defaults.headers.common['Content-Type'] = 'application/json';
    axios.defaults.withCredentials = true;
    if (token) {
      axios.defaults.headers.common['Authorization'] = 'Bearer ' + token;
    }
  })();
};

export const toTitleCase = (text: string, replace: boolean = true) => {
  if (typeof text !== 'string') return text;
  if (replace) {
    text = text?.replaceAll('_', ' ');
  }
  return text?.replace(/\w\S*/g, function (txt) {
    return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
  });
};

export const toKey = (text: string) => {
  if (!text) {
    return '';
  }
  text = text?.replaceAll(' ', '_');
  return text?.replace(/\w\S*/g, function (txt) {
    return txt.toLowerCase();
  });
};

export const removeEmpty = (obj: any) => {
  Object.keys(obj).forEach((key) => {
    if (obj[key] === null || obj[key] === undefined || obj[key] === '') {
      delete obj[key];
    }
  });
  return obj;
};

export const amountToInt = (amount?: any) => {
  if (!amount) return null;
  if (typeof amount === 'number') return amount;
  amount = amount.replaceAll(',', '');
  return parseInt(amount);
};

export const toAmount = (amount: any) => {
  if (amount === 0 || amount === '0') return `${CURRENCY_SYMBOL} 0`;
  if (!amount) return null;
  if (typeof amount === 'string') return `${CURRENCY_SYMBOL} ${parseInt(amount)?.toLocaleString(LOCALE)}`;
  if (typeof amount === 'number') return `${CURRENCY_SYMBOL} ${amount?.toLocaleString(LOCALE)}`;
};

export const sleep = (ms: number) => new Promise((res) => setTimeout(res, ms));

export const errorHandler = (response: any) => {
  if (response?.error instanceof Object) {
    return;
  } else if (typeof response?.data?.error === 'string') {
    errorNotification(response?.data?.error);
  } else if (typeof response?.error_description === 'string') {
    errorNotification(response?.error_description);
  } else if (typeof response?.error === 'string') {
    errorNotification(response?.error);
  } else if (response?.data?.error?.non_field_error) {
    errorNotification(response?.data?.error?.non_field_error?.[0]);
  } else if (response?.data?.error?.self_details?.non_field_errors) {
    errorNotification(response?.data?.error?.self_details?.non_field_errors?.[0]);
  } else if (response?.data?.error?.error_description) {
    errorNotification(response?.data?.error?.error_description);
  } else if (response?.data?.error_description) {
    errorNotification(response?.data?.error_description);
  } else if (response?.otp?.[0]) {
    errorNotification(response?.otp?.[0]);
  } else if (response?.detail) {
    errorNotification(response?.detail);
  } else if (typeof response?.message === 'string') {
    errorNotification(response?.message);
  } else if (response?.data?.error?.self_details?.phone) {
    errorNotification(response?.data?.error?.self_details?.phone?.[0]);
  } else {
    errorNotification('Something went wrong');
  }
};

export const mobileNumber = (number: any) => {
  if (typeof number === 'string' && number?.length === 10) {
    return `+91${number}`;
  } else if (typeof number === 'number' && number.toString()?.length === 10) {
    return `+91${number}`;
  } else {
    return `${number}`;
  }
};

export const cleanPayload = (payload: any) => {
  return Object.fromEntries(Object.entries(payload).filter(([_, v]) => v !== null && v !== ''));
};

export const nullIfEmpty = (payload: any) => {
  let item = {} as any;
  for (const [key, value] of Object.entries(payload)) {
    item[key] = value !== null || value !== undefined || value !== '' ? value : null;
  }
  return item;
};

export const countingDays = (date: string) => {
  const today = new Date(),
    renewalDate = new Date(date),
    diffTime = Math.abs(renewalDate.getTime() - today.getTime()),
    diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
  return diffDays;
};

export const addProductToMemberSanityCheck = (values: any) => {
  let isDataValid = true;
  if (isDataValid) {
    if (values?.policies?.length === 0 && values?.subscriptions?.length === 0) {
      errorNotification('Please select atleast one product');
      isDataValid = false;
      return false;
    }
  }
  if (isDataValid) {
    values?.policies?.forEach((element: any) => {
      if (!element?.sum_insured) {
        errorNotification(`Please select a sum insured for ${element?.product_type?.name}`);
        isDataValid = false;
        return false;
      }
      if (
        element?.product_type?.short_name === 'GTP' &&
        (element?.deductible === null || element?.deductible === undefined)
      ) {
        errorNotification(`Please select a deductible for ${element?.product_type?.name}`);
        isDataValid = false;
        return false;
      }
    });
  }
  if (isDataValid) {
    values?.subscriptions?.forEach((element: any) => {
      if (element?.plan && typeof element?.plan !== 'string') {
        errorNotification(`Please select a plan for ${element?.product_type?.name}`);
        isDataValid = false;
        return false;
      }
    });
  }
  return isDataValid;
};

export const findStrings = (obj: any) => {
  let strings: any = [];
  for (let key in obj) {
    if (typeof obj[key] === 'string') {
      strings.push(obj[key]);
    } else if (typeof obj[key] === 'object') {
      strings = strings.concat(findStrings(obj[key]));
    }
  }
  return strings;
};

export const timeAgo = (from: any) => {
  const diffInMinutes = moment().diff(from, 'minutes');

  if (diffInMinutes < 60) {
    return `${diffInMinutes} mins ago`;
  }

  const diffInHours = Math.floor(diffInMinutes / 60);
  if (diffInHours < 24) {
    return `${diffInHours} hours ago`;
  }

  const diffInDays = Math.floor(diffInMinutes / 1440);
  if (diffInDays === 1) {
    return 'yesterday';
  } else if (diffInDays < 7) {
    return `${diffInDays} days ago`;
  }

  const diffInWeeks = Math.floor(diffInMinutes / 10080);
  if (diffInWeeks < 4) {
    return `${diffInWeeks} weeks ago`;
  }

  const diffInMonths = Math.floor(diffInMinutes / 43200);
  return `${diffInMonths} months ago`;
};
export const renderCoverageType = (type: string) => {
  switch (type) {
    case 'e':
      return 'Employee only';
    case 'es':
      return 'Employee and Spouse';
    case 'esc':
      return 'Employee, Spouse and Children';
    case 'escp':
      return 'Employee, Spouse, Children and Parents';
    default:
      return 'Employee only';
  }
};

export const renderMembers = (coverageType: any, formData: any) => {
  const memberRelations = [] as any;
  if (coverageType?.includes('e')) {
    memberRelations.push('SELF');
  }
  if (coverageType?.includes('s')) {
    memberRelations.push('SPOUSE');
  }
  if (coverageType?.includes('c')) {
    memberRelations.push('CHILD');
  }
  if (coverageType?.includes('p')) {
    memberRelations.push('PARENTS');
    memberRelations.push('PARENTS_IN_LAW');
  }
  let allNames = [{ full_name: formData?.full_name }] as any;
  let names = formData?.dependent?.filter((item: any) => memberRelations.includes(item?.relation?.toUpperCase()));
  allNames = allNames?.concat(names);
  return allNames?.map((item: any) => item?.full_name).join(', ');
};

export const createMarkup = (html: any) => {
  return {
    __html: DOMPurify.sanitize(html),
  };
};

export const useAnalyticsEventTracker = (category: string, params?: object) => ReactGA.event(category, params);

export const formatPhone = (phone?: string, digits = 10) => {
  if (!phone) return '';
  return phone.replace(/[^0-9]/g, '').slice(phone.length - digits);
};

export const containsStringInPath = (substring: string) => {
  return window.location.pathname.includes(substring);
};

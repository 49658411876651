import { Result, Spin } from 'antd';
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { matchPath, useLocation, useNavigate } from 'react-router-dom';
import '../../../src/RetailApp.scss';
import '../../../src/retail-antd-custom.scss';
import retailApi from '../../api/retailApi';
import Footer from '../../components/Retail/Footer/Footer';
import Header from '../../components/Retail/Header/Header';
import { errorNotification } from '../../components/UI/Toast/Toast';
import Router from '../../router/Router';
import { dataActions } from '../../store/data';
import { themeActions } from '../../store/theme';
import { errorHandler, getRetailAccessToken, setLocalData } from '../../utils/helper';
import api from '../../api/api';

const Layout: React.FC = (props: any) => {
  const referrer = document.referrer,
    location = useLocation(),
    [theme, setTheme] = useState<any>({
      primaryColor: '',
      favicon: '',
      title: '',
      description: '',
    }),
    [loading, setLoading] = useState(true),
    dispatch = useDispatch(),
    [validReferrer, setValidReferrer] = useState(false),
    // partner = window.location.pathname?.includes('retail-partner') ? window.location.pathname.split('/')[2] : false,
    [partner, setPartner] = useState(undefined as any),
    navigate = useNavigate(),
    getChoices = () => {
      retailApi.user
        .getChoices({
          ...(partner ? { slug: partner } : {}),
        })
        .then((response) => {
          console.log(response);
          if (response?.data?.content_choices?.organisation) {
            setValidReferrer(true);
            setLoading(false);
          }
          dispatch(dataActions.setRetailChoices({ choices: response?.data }));
        })
        .catch((err: any) => { });
    },
    getPartnerDetails = () => {
      retailApi.partner.getPartnerDetails({ slug: partner })
        .then((res) => {
          if (res?.data?.referral === null || res?.data?.referral?.length === 0 || res?.data?.referral?.includes(referrer)) {
            setValidReferrer(true);
            setLoading(false);
          }
          else {
            setValidReferrer(false);
            setLoading(false);
          }
        })
        .catch((err) => {
          setLoading(false);
          errorHandler(err?.response?.data);
        })
    },
    getBrokerConfig = (organisation_id?: any) => {
      setLoading(true);
      api.broker.getBrokerConfig()
        .then((response: any) => {
          dispatch(themeActions.setTheme({
            primaryColor: response?.data?.organisation_config?.theme_color ? response?.data?.organisation_config?.theme_color : response?.data?.theme_color,
            logoUrl: response?.data?.organisation_config?.logo_url ? response?.data?.organisation_config?.logo_url : response?.data?.logo_url,
            brandName: response?.data?.organisation_config?.brand_name,
            loginOptions: response?.data?.login_services || {},
            isDark: response?.data?.organisation_config?.is_dark,
            brokerName: response?.data?.brand_name,
            description: response?.data?.addition_details?.tagline || `Employee Benefits Platform`,
            supportPhone: response?.data?.support_phone || '',
            supportEmail: response?.data?.support_email || '',
          }));
          setTheme({
            primaryColor: response?.data?.organisation_config?.theme_color ? response?.data?.organisation_config?.theme_color : response?.data?.theme_color,
            logoUrl: response?.data?.organisation_config?.logo_url ? response?.data?.organisation_config?.logo_url : response?.data?.logo_url,
            favicon: response?.data?.favicon_url,
            title: response?.data?.brand_name,
            description: response?.data?.addition_details?.tagline || `Employee Benefits Platform`,
            loginOptions: response?.data?.login_services || {},
            isDark: response?.data?.organisation_config?.is_dark,
          });
          if (response?.data?.addition_details?.client?.mixpanel) {
            setLocalData('mixpanel', response?.data?.addition_details?.client?.mixpanel);
          }
          if (response?.data?.addition_details?.client?.custom_code) {
            var script = document.createElement('script');
            script.type = 'text/javascript';
            script.innerHTML = response?.data?.addition_details?.client?.custom_code;
            document.head.appendChild(script);
          }
          if (response?.data?.client?.custom_code) {
            var script = document.createElement('script');
            script.type = 'text/javascript';
            script.innerHTML = response?.data?.client?.custom_code;
            document.head.appendChild(script);
          }
          if (organisation_id) {
            setLoading(false);
          }
        })
        .catch((error: any) => {
          errorHandler(error?.response?.data);
          if (organisation_id) {
            setLoading(false);
          }
        })
    };
  // { partner } = useParams();


  // useEffect(() => {
  //   // let partner = window.location.pathname.split('/')[1];
  //   console.log(choices, partner);
  //   if (partner) {
  //     getPartnerDetails(partner)
  //   }
  //   else if (choices) {
  //     console.log(choices)
  //     if (choices?.organisation_uuid) {
  //       setValidReferrer(true);
  //       setLoading(false);
  //     }
  //     if (!choices?.organisation_uuid) {
  //       setValidReferrer(false);
  //       setLoading(false);
  //     }
  //   }
  //   else {
  //     console.log('thiss')
  //   }
  // }, [partner, choices]);

  useEffect(() => {
    const routePattern = "/retail-partner/:partner/*";
    const match = matchPath(routePattern, location.pathname);

    if (match) {
      const partner = match?.params?.partner;
      setPartner(partner);
      // setValidReferrer(false)
      console.log('Partner', partner);
    }
    else {
      setTimeout(() => {
        if (!getRetailAccessToken()) {
          errorNotification('Please login to access the page');
          setLocalData('targetUrl', window.location.href.split('/').slice(3, 0).join('/'));
          navigate('/');
        }
        // setValidReferrer(false);
        // setLoading(false);
      }, 2000)

    }
  }, [location]);

  useEffect(() => {
    if (partner) {
      getPartnerDetails();
    }
    else {
      if (getRetailAccessToken()) {
        setLoading(false);
        setValidReferrer(true);
        // getChoices();
      }
    }
  }, [partner]);

  useEffect(() => {
    document.title = theme.title + ': ' + theme.description;
    document.querySelector('meta[name="description"]')?.setAttribute('content', theme.description);
    document.querySelector('meta[name="theme-color"]')?.setAttribute('content', theme.primaryColor);
    document.querySelector('meta[name="msapplication-navbutton-color"]')?.setAttribute('content', theme.primaryColor);
    document.querySelector('meta[name="apple-mobile-web-app-status-bar-style"]')?.setAttribute('content', theme.primaryColor);
    let link: any = document.querySelector("link[rel~='icon']")
    if (!link) {
      link = document.createElement('link');
      link.rel = 'icon';
      document.getElementsByTagName('head')[0].appendChild(link);
    }
    link.href = theme.favicon;

    // Dynamic manifest
    var myDynamicManifest = {
      "name": theme.title,
      "short_name": theme.title,
      "description": theme.description,
      "background_color": "#000000",
      "theme_color": theme.primaryColor,
      "start_url": ".",
      "scope": ".",
      "display": "standalone",
      "icons": [
        {
          "src": theme.favicon,
          "sizes": "64x64 32x32 24x24 16x16",
          "type": "image/x-icon",
          "purpose": "any maskable"
        },
        {
          "src": theme.favicon,
          "sizes": "192x192",
          "type": "image/png",
          "purpose": "any maskable"
        },
        {
          "src": theme.favicon,
          "sizes": "512x512",
          "type": "image/png",
          "purpose": "any maskable"
        },
      ]
    }
    const stringManifest = JSON.stringify(myDynamicManifest);
    const blob = new Blob([stringManifest], { type: 'application/json' });
    const manifestURL = URL.createObjectURL(blob);
    let maniFest: any = document.querySelector("#my-manifest-placeholder");
    maniFest.href = manifestURL;
  }, [theme])

  return (
    <div>
      <Header />
      {loading &&
        <Result
          icon={<Spin />}
          style={{ marginTop: '20vh', marginBottom: '20vh' }}
          title="Checking Eligiblity"
          subTitle={`Checking if you are eligible to avail these offers.`}
        />
      }
      {!validReferrer && !loading &&
        <Result
          status="warning"
          style={{ marginTop: '20vh', marginBottom: '20vh' }}
          title="Invalid Referrer"
          subTitle={`You are not elgible for these offers.`}
        />
      }
      {validReferrer && !loading &&
        <Router />
      }
      <Footer />
    </div>
  );
};

export default Layout;
import { API_URL } from '../constants/config';
const apiEndpoints = {
  authenticate: API_URL + 'profile/login',
  refreshToken: API_URL + 'profile/token',
  userData: API_URL + 'profile/employer/user',
  ssoInfo: API_URL + 'profile/app/sso/user-info',
  orgDetails: API_URL + 'organisations/organisation/',
  modifyProfile: API_URL + 'organisations/employee/profile_modification/',
  modificationTracker: API_URL + 'organisations/employee/profile_modification_tracker/',
  requestOTP: API_URL + 'profile/request-otp',
  requestOTPBP: API_URL + 'profile/request-otp-bima-premium',
  phoneFromIDBP: API_URL + 'profile/phone-from-employee-id-bima-premium',
  loginWithOTPBP: API_URL + 'profile/login-via-otp-bima-premium',
  loginWithOTP: API_URL + 'profile/login-via-otp',
  forgotPassword: API_URL + 'profile/user/forgot-password-email',
  resetPassword: API_URL + 'profile/user/reset-password',
  changePassword: API_URL + 'profile/user/change-password',
  members: API_URL + 'organisations/employer/organisation_member',
  membersV1: API_URL + 'organisations/employer/organisation_member_v1',
  membersV2: API_URL + 'organisations/employer/organisation_member_v2',
  memberRemove: API_URL + 'organisations/member_status',
  hardDeleteMember: API_URL + 'organisations/employer/hard_member_remove',
  hardDeleteMemberEmployee: API_URL + 'organisations/employee/hard_member_remove',
  dependantRemove: API_URL + 'organisations/employer/member_remove',
  products: API_URL + 'organisations/employer/products-list',
  policyStats: API_URL + 'organisations/employer/product_stats',
  addProducts: API_URL + 'organisations/product_subscription',
  selectChoices: API_URL + 'organisations/filter',
  getClaims: API_URL + 'claims/employer/organisation_claims',
  claimStats: API_URL + 'claims/employer/claims_stats',
  memberDetails: API_URL + 'organisations/employee/organisation_member_detail',
  employeeClaims: API_URL + 'claims/employee/member_claims',
  employeeClaimStats: API_URL + 'claims/employee/claims_stats',
  employeeProducts: API_URL + 'claims/employee/product_details',
  generateEcard: API_URL + 'ecard/employee/generate/',
  employerGenerateEcard: API_URL + 'ecard/employer/generate/',
  productBenefit: API_URL + 'masterdata/employee/get_client_product_benifit',
  UHIFamilyListing: API_URL + 'product_integration/uhi_family_member',
  UHIPremiumCalculator: API_URL + 'product_integration/uhi_premium',
  UHIAdditionalDetails: API_URL + 'product_integration/uhi_family_member_detail',
  UHIProposal: API_URL + 'product_integration/manipal_signa/proposal_api',
  UHIAddMember: API_URL + 'product_integration/add_uhi_family_member',
  paymentRequest: API_URL + 'billing/payment_order',
  paymentStatus: API_URL + 'billing/verify_payment_order',
  UHIPolicyDocs: API_URL + 'product_integration/manipal_signa/celery_task',
  organisationMIS: API_URL + 'organisations/employer/member_mis',
  uploadTemplate: API_URL + 'endorsement/employer/generate_template/',
  memberDataReview: API_URL + 'organisations/employer/member_review/',
  memberDataSubmit: API_URL + 'organisations/member_upload/',
  memberDataDelete: API_URL + 'organisations/employer/bulk_member_delete/',
  stepsToAvail: API_URL + 'masterdata/subscription_benefits',
  generateCoupon: API_URL + 'masterdata/employee/generate_coupon',
  activatePlan: API_URL + 'masterdata/employee/activate_plan',
  UHIFilter: API_URL + 'product_integration/filter',
  UHIApplicationDetail: API_URL + 'product_integration/uhi_member_detail',
  UHIValidations: API_URL + 'product_integration/manipal_signa/manipal_validation',
  inviteMemberDetails: API_URL + 'organisations/employer/organisation_invite_member',
  inviteMemberProducts: API_URL + 'organisations/employer/invite_product_subscribe',
  invitedMemberDetails: API_URL + 'organisations/employee/organisation_member_invite_detail',
  bulkInviteMembers: API_URL + 'organisations/employer/bulk_member_invite/',
  checkCoverageType: API_URL + 'organisations/employee/check_coverage_type',
  resendInvite: API_URL + 'organisations/employer/resend-invite',
  googleLogin: API_URL + 'profile/google-login/',
  microsoftLogin: API_URL + 'profile/microsoft-login/',
  updateSelfDetails: API_URL + 'organisations/employer/single_member_upload/',
  selfProducts: API_URL + 'organisations/employer/organisation_product_update',
  brokerConfig: API_URL + 'broker/broker-config',
  ftiRenewal: API_URL + 'product_integration/manipal_signa/manipal_policy_info',
  claimDocuments: API_URL + 'documents/documents',
  knitAuth: API_URL + 'product_integration/knit/auth/',
  knitAppList: API_URL + 'product_integration/knit/apps_list',
  knitConfig: API_URL + 'product_integration/knit/configuration',
  hrmsMemberListing: API_URL + 'product_integration/knit/hrms_member',
  hrmsMemberStatus: API_URL + 'product_integration/knit/status_update',
  hrmsProductSubscription: API_URL + 'product_integration/knit/product_subscription',
  notificationSetting: API_URL + 'notification/subscription',
  notification: API_URL + 'notification/notification',
  notificationCount: API_URL + 'notification/count',
  hrmsSync: API_URL + 'product_integration/knit/sync',
  inviteMembersV1: API_URL + 'organisations/employer/organisation_invite_member_v1',
  hrmsFilters: API_URL + 'product_integration/knit/filter',
  requestKYCOTP: API_URL + 'utils/generate-kyc-otp',
  submitKYCOTP: API_URL + 'utils/submit-kyc-otp',
  claimConfig: API_URL + 'claims/claims_api_config',
  claimSync: API_URL + 'claims/execute_claims_api',
  checkSSO: API_URL + 'profile/app/login',
  getModificationRequests: API_URL + 'endorsement/employer/policy-endorsement',
  getModificationCount: API_URL + 'endorsement/employer/policy-endorsement-count',
  cancelEndorsement: API_URL + 'organisations/employee/cancel_endoresment/',
  logout: API_URL + 'profile/logout',
  policyDocuments: API_URL + 'policies/employer/get-policy-documents',

  tartanHrms: API_URL + 'product_integration/tartan/auth/',
  tartanAppList: API_URL + 'product_integration/tartan/hrms_list',
  tartanMemberList: API_URL + 'product_integration/tartan/hrms_member',
  tartanFilter: API_URL + 'product_integration/tartan/filter',
  tartanStatusUpdate: API_URL + 'product_integration/tartan/status_update',
  tartanProductSubscription: API_URL + 'product_integration/tartan/product_subscription',
  tartanConfig: API_URL + 'product_integration/tartan/configuration',
  //retail
  retail_authenticate: API_URL + 'profile/login',
  retail_refreshToken: API_URL + 'auth/token',
  retail_userProfile: API_URL + 'partners/partner-member-profile',
  retail_getPartner: API_URL + 'partners/partner-provider',
  retail_getProducts: API_URL + 'partners/partner-product',
  retail_paymentRequest: API_URL + 'billing/partner_payment_order',
  retail_paymentStatus: API_URL + 'billing/verify_payment_order_v1',
  retail_manageCart: API_URL + 'carts/cart-item',
  retail_cartCount: API_URL + 'carts/cart-items-count',
  retail_requestOTP: API_URL + 'profile/request-otp',
  retail_verifyOTP: API_URL + 'profile/signup-login-via-otp',
  retail_purchasedCoupons: API_URL + 'partners/partner-member-coupon',
  retail_purchasedCouponsV1: API_URL + 'carts/cart-member-coupon',
  retail_partnerDetails: API_URL + 'partners/partner-details',
  retail_getProductsV2: API_URL + 'organisations/employee/organisation_retail_offer_plan_list',
  retail_filter: API_URL + 'masterdata/filter',
  retail_plansAndOffers: API_URL + 'organisations/employee/organisation_retail_offer_plan_list',
  retail_slugPlansAndOffers: API_URL + 'partners/partner-user-offer-plans',
  retail_redeemOffer: API_URL + 'masterdata/redeem_offer',
  retail_paymentRequestV1: API_URL + 'billing/payment_order_v1',
};

export default apiEndpoints;

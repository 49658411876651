import { Col, Image, Row } from 'antd';
import moment from 'moment';
import React from 'react';
import { useSelector } from 'react-redux';
import google from '../../../assets/retail/icons/Google.svg';
import inc42 from '../../../assets/retail/icons/Inc42.svg';
import email from '../../../assets/retail/icons/email2.svg';
import phone from '../../../assets/retail/icons/phone2.svg';
import { MAIN_SITE_URL } from '../../../constants/config';
import './_footer.scss';
import { containsStringInPath } from '../../../utils/helper';

const Footer: React.FC = () => {
  const isSQ = containsStringInPath('suraksha-qr');
  const footerLinks = [
    // [
    //   {
    //     title: 'Home',
    //     link: MAIN_SITE_URL,
    //   },
    //   // {
    //   //   title: 'Get Quote',
    //   //   link: '#',
    //   // },
    //   {
    //     title: 'How it Works',
    //     link: MAIN_SITE_URL + '/how-it-works',
    //   },
    //   {
    //     title: 'FAQs',
    //     link: MAIN_SITE_URL + '/faq',
    //   }
    // ],
    // [
    //   {
    //     title: 'About Us',
    //     link: MAIN_SITE_URL + '/about-us',
    //   },
    //   {
    //     title: 'Careers',
    //     link: MAIN_SITE_URL + '/careers',
    //   },
    //   {
    //     title: 'Blog',
    //     link: MAIN_SITE_URL + '/blog',
    //   },
    //   // {
    //   //   key: 1,
    //   //   title: 'Join Us',
    //   //   link: '#',
    //   // },
    //   // {
    //   //   key: 1,
    //   //   title: 'Partner with Us',
    //   //   link: '#',
    //   // }
    // ],
    [
      {
        title: 'Privacy Policy',
        link: MAIN_SITE_URL + '/privacy-policy',
      },
      {
        title: 'Terms of Use',
        link: MAIN_SITE_URL + '/terms-of-use',
      },
      {
        title: 'Refund Policy',
        link: MAIN_SITE_URL + '/refund-policy',
      },
      {
        title: 'Contact us',
        link: MAIN_SITE_URL + '/contact-us',
      }
    ]
  ], sFooterLinks = [
    [
      {
        title: 'Privacy Policy',
        link: 'https://surakshaqr.com/privacy-policy/',
      },
      {
        title: 'Terms of Use',
        link: 'https://surakshaqr.com/terms-conditions/',
      },
      {
        title: 'Refund Policy',
        link: 'https://surakshaqr.com/refund-policy/',
      },
      {
        title: 'Contact us',
        link: 'https://surakshaqr.com/contact-us/',
      }
    ]
  ],themeDetails = useSelector((state: any) => state.theme),
    footerTitles = ['Information'],
    footerSocials = [
      {
        icon: phone,
        link: `tel:${themeDetails?.supportPhone}`,
      },
      {
        icon: email,
        link: `mailto:${themeDetails?.supportEmail}`,
      },
      // {
      //   key: 2,
      //   icon: facebook,
      //   link: '#',
      // },
      // {
      //   icon: linkedin,
      //   link: 'https://www.linkedin.com/company/bharatsure/',
      // }
    ],
    badges = [
      {
        icon: inc42,
        link: 'https://inc42.com/startups/30-startups-to-watch-startups-that-caught-our-eye-in-june-2022-fintech-edition/#cxrecs_s',
        alt: "Healthysure in Inc42's Startup to Watch",
      },
      {
        icon: google,
        link: 'https://goo.gl/maps/bvgfVG8VMFVagpwE6',
        alt: "Healthysure, 5 star service ratings by employees",
      }
    ]



  return (
    <div className='retail-footer'>
      <Row justify='space-between' gutter={[0, 48]} align='top' className='retail-container full-width'>
        <Col xxl={14} xl={14} lg={14} md={14} sm={24} xs={24}>
          {/* <a href={MAIN_SITE_URL} target='_blank'><Image src={themeDetails?.logoUrl} preview={false} /></a> */}
          <div className="footer-brand-name">
            {isSQ?"Suraksha QR":themeDetails?.brokerName}
          </div>
          <div className="footer-tagline">
            {isSQ?"":themeDetails?.description}
          </div>
          <Row align="middle" gutter={24}>
            {footerSocials.map((social, index) => {
              return (
                <Col key={index}>
                  <a href={social.link} target='_blank'>
                    <Image src={social.icon} preview={false} />
                  </a>
                </Col>
              )
            })}
          </Row>
          {/* <Row align="middle" gutter={24} className='footer-badges'>
            {badges.map((badge, index) => {
              return (
                <Col key={index}>
                  <a href={badge.link} target='_blank'>
                    <Image src={badge.icon} preview={false} alt={badge.alt} />
                  </a>
                </Col>
              )
            })}
          </Row> */}
        </Col>
        {!isSQ && footerLinks.map((links, index) => {
          return (
            <Col key={index} xxl={3} xl={3} lg={3} md={3} sm={8} xs={8}>
              <Row gutter={[0, 16]}>
                <Col className="footer-heading">
                  {footerTitles[index]}
                </Col>
                {links.map((link, index) => {
                  return (
                    <Col key={index}>
                      <a href={link.link} className="footer-links">{link.title}</a>
                    </Col>
                  )
                })}
              </Row>
            </Col>
          )
        })}
        {isSQ && sFooterLinks.map((links, index) => {
          return (
            <Col key={index} xxl={3} xl={3} lg={3} md={3} sm={8} xs={8}>
              <Row gutter={[0, 16]}>
                <Col className="footer-heading">
                  {footerTitles[index]}
                </Col>
                {links.map((link, index) => {
                  return (
                    <Col key={index}>
                      <a href={link.link} className="footer-links">{link.title}</a>
                    </Col>
                  )
                })}
              </Row>
            </Col>
          )
        })}
        <Col span={24} className='footnote'>
          <Row className='text-center' align='middle' justify='center'>
            © All Rights Reserved. {isSQ?"Suraksha QR":themeDetails?.brokerName} @ {moment()?.format('YYYY')}
          </Row>
          {themeDetails?.brokerName !== 'Bharatsure' && <Row className='text-center mt-8' align='middle' justify='center'>
            Solutions provided by Bharatsure
          </Row>}
        </Col>
      </Row>
    </div>
  )
};

export default Footer;
